import React from 'react';
import FwdNavBar from './navbar';
import FwdFooter from './footer';
import FwdBody from './body/body';
import Privacy from './legal/privacy';
import Terms from './legal/terms';
import ScrollToTop from './utilities/scrollToTop'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {firebaseDB} from '../firebase.js';

class Root extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop />
          <FwdNavBar />
          <Switch>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/">
              <FwdBody />
            </Route>
          </Switch>
          <FwdFooter />
        </Router>
      </React.Fragment>
    );
  }
}

export default Root;