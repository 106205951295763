import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCyB05SMX3IBhtbKTMLcPfQdX4ZmX9yCec",
  authDomain: "fwdfinancial.firebaseapp.com",
  databaseURL: "https://fwdfinancial.firebaseio.com",
  projectId: "fwdfinancial",
  storageBucket: "fwdfinancial.appspot.com",
  messagingSenderId: "843930529528",
  appId: "1:843930529528:web:28c2a97e8d481cff7f550d",
  measurementId: "G-2LNY9R93SY"
};

firebase.initializeApp(firebaseConfig);

export const firebaseDB = firebase.database().ref();