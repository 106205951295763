import React from 'react';
import Container from 'react-bootstrap/Container';

class Terms extends React.Component {
  componentDidMount() {
    document.title = 'Terms of Service | FWD Financial';
  }

  render() {
    return (
      <React.Fragment>
        <Container id="terms" style={{padding: "76px 8px"}}>
          <h1>Terms of Service</h1>
          <p><i>Last updated: June 2020</i></p>
          <p>These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with MyDebt (the "Service") operated by FWD Financial, 2198893 Alberta LTD. ("us", "we", or "our").</p>
          <p>Please read these Terms and Conditions carefully before using MyDebt.</p>
          <p>Your access to and use of the Service is based on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
          <p>By accessing or using the Service you agree to be bound by these Terms and accept all legal consequences. If you do not agree to these terms and conditions, in whole or in part, please do not use the Service.</p>
          <h5>1. MODIFICATIONS TO THE TERMS AND CONDITIONS</h5>
          <p>We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Service. You agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.</p>
          <p>The information and material on the Service, and the Service, may be changed, withdrawn or terminated at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Service is restricted to users or unavailable at any time or for any period.</p>
          <h5>2. YOUR USE OF THE SERVICE; ACCOUNT SET-UP AND SECURITY</h5>
          <p>The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
          <p>The safety and security of your information also depends on you. Users are responsible for obtaining their own access to the Service. Users are required to ensure that all persons who access the Service through a user's account are aware of these Terms and Conditions and comply with them. The Service requires user registration. It is a condition of your use of the Service that all the information you provide is correct, current, and complete.</p>
          <p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Service. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Service.</p>
          <p>Your provision of registration information and any submissions you make to the Service through any functionality constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy, found here. </p>
          <p>Any username, password, or any other piece of information chosen by you, or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this Service using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security.  You are responsible for any password misuse or any unauthorized access.</p>
          <p>We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.</p>
          <p>You are prohibited from attempting to circumvent and from violating the security of this Service, including, without limitation:</p>
          <ol>
            <li>accessing content and data that is not intended for you;</li>
            <li>attempting to breach or breaching the security and/or authentication measures which are not authorized;</li>
            <li>restricting, disrupting or disabling service to users, hosts, servers or networks;</li>
            <li>illicitly reproducing TCP/IP packet header;</li>
            <li>disrupting network services and otherwise disrupting Website owner's ability to monitor the Website;</li>
            <li>using any robot, spider, or other automatic device, process, or means to access the Service for any purpose, including monitoring or copying any of the material on the Service</li>
            <li>introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;</li>
            <li>attacking the Service via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing or crashing; and,</li>
            <li>otherwise attempting to interfere with the proper working of the Service.</li>
          </ol>
          <h5>3. AVAILABILITY, ERRORS, AND INACCURACIES</h5>
          <p>In order to provide exceptional service, and accuracy, we regularly update the services on the Service.</p>
          <p>We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>
          <p>We may experience delays in updating information on the Service and in our advertising on other websites.</p>
          <h5>4. CONTENT</h5>
          <p>You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</p>
          <p>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.</p>
          <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
          <p>You acknowledge that, by providing you with the ability to view and distribute user-generated content on the Service, we are merely acting as a passive conduit for such distribution and is not undertaking any obligation or liability relating to any contents or activities on the Service.</p>
          <h5>5. ACCOUNTS</h5>
          <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
          <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
          <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
          <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
          <h5>6. INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP</h5>
          <p>You understand and agree that the Service and its entire contents, features, and functionality, including, but not limited to, all information, software, code, text, displays, graphics, photographs, video, audio, design, presentation, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.</p>
          <p>The Company name, and all related names, logos, product and service names, designs, images and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images and slogans mentioned, or which appear on this Service are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or other laws and could subject the infringer to legal action.</p>
          <p>You may only use the Service for your personal and non-commercial use. You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Service, in any form or medium whatsoever except:</p>
          <ol>
            <li>your phone and browser may temporarily store or cache copies of materials being accessed and viewed;</li>
            <li>in the event social media platforms are linked to certain content on our Service, you may take such actions as our Service and such third-party social media platforms permit.</li>
          </ol>
          <p>Users are not permitted to modify copies of any materials from this Website nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must not access or use for any commercial purposes any part of the Service or any services or materials available through the Service.</p>
          <p>If you print off, copy or download any part of our Service in breach of these Terms and Conditions, your right to use the Service will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the Service or to any content on the Service, and all rights not expressly granted are reserved by the Company. Any use of the Service not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may infringe or violate copyright, trademark, and other intellectual property or other proprietary laws.</p>
          <h5>7. LINKS TO OTHER WEBSITES</h5>
          <p>The Service may contain links to third-party web sites or services that are not owned or controlled by FWD Financial.</p>
          <p>FWD Financial has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that FWD Financial shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
          <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
          <h5>8. TERMINATION</h5>
          <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>
          <p>All provisions of the Terms shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
          <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
          <h5>9. INDEMNIFICATION</h5>
          <p>You agree to indemnify, defend and hold harmless FWD Financial, its principals, officers, directors, representatives, employees, contractors, licensors, licensees, suppliers and agents, from and against any claims, losses, damages, losses, obligations, costs, actions or demands.</p>
          <p>These include but are not limited to: (a) legal and accounting fees resulting from your use of the Service; (b) your breach of any of these Terms; (c) anything you post on or upload to the Service; and (d) any activity related to your account. This includes any negligent or illegal conduct by you, any person or entity accessing the Service using your account whether such access is obtained via fraudulent or illegal means.</p>
          <h5>10. LIMITATION OF LIABILITIES</h5>
          <p>FWD Financial, its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any loss or damage, direct or indirect, incidental, special, consequential or punitive damages, including without limitation, economic loss, loss or damage to electronic media or data, goodwill, or other intangible losses, resulting from (i) your access to or use of the Service; (ii) your inability to access or use the Service; (iii) any conduct or content of any third-party on or related to the Service; (iiv) any content obtained from or through the Service; and (v) the unauthorized access to, use of or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other claim in law, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
          <h5>11. DISCLAIMER AND NON-WAIVER OF RIGHTS</h5>
          <p>FWD Financial makes no guarantees, representations or warranties of any kind as regards the Service and associated technology. Any purportedly applicable warranties, terms and conditions are excluded, to the fullest extent permitted by law. </p>
          <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance, except as provided for under the laws of any province in Canada. In such cases, the provincial law shall apply to the extent necessary.</p>
          <p>FWD Financial, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
          <p>If you breach any of these Terms and FWD Financial chooses not to immediately act, or chooses not to act at all, FWD Financial will still be entitled to all rights and remedies at any later date, or in any other situation, where you breach these Terms. FWD Financial does not waive any of its rights. FWD Financial shall not be responsible for any purported breach of these Terms caused by circumstances beyond its control. A person who is not a party to these Terms shall have no rights of enforcement. You may not assign, sub-license or otherwise transfer any of your rights under these Terms.</p>
          <h5>12. EXCLUSIONS</h5>
          <p>As set out, above, some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you. Provincial laws of Canada may apply to certain products and service provided.</p>
          <h5>13. GOVERNING LAW</h5>
          <p>the laws in the Province of Alberta and the laws of Canada, as applicable.</p>
          <p>If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, then any remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements, oral or otherwise, regarding the Service.</p>
          <h5>14. CHANGES</h5>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
          <h5>15. ENTIRE AGREEMENT</h5>
          <p>The Terms and Conditions and/, our Privacy Policy, and any other relevant Agreement constitute the sole and entire agreement between you and FWD Financial regarding the Service and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, regarding such subject matter.</p>
          <h5>16. CONTACT US</h5>
          <p>If you have any questions about these Terms, please contact us.</p>
        </Container>
      </React.Fragment>
    );
  }
}

export default Terms;